/**
 * @auther  yuyuanda
 *  入口文件
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";

// 全局引入按需引入UI库 vant
import "vant/lib/index.css";
// 此处按需引入，需要你自己添加vant组件
import "@/core/plugins/vant";
// 引入elementUI的样式
import "element-ui/lib/theme-chalk/index.css";
// 按需引入elementUI组件
import "@/core/plugins/element";

import "@/core/mainimport";
import "./styles/index.css";

Vue.config.productionTip = false;
Vue.config.devtools = true;
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
