/**
 * 没太大意义，只是为了美观。
 * 将main.js中过多的代码放到这里面的
 */
import Vue from 'vue'
import { post, get, respTip } from '@/core/api/request'
Vue.prototype.$get = get
Vue.prototype.$post = post
Vue.prototype.$tip = respTip

import dayjs from 'dayjs'
Vue.prototype.$d = dayjs

import Bus from './EventBus'; 
Vue.prototype.$bus = Bus

import Debounce from '@/core/directive/directive.js' // 防抖自定义指令
Debounce(Vue) // 添加防抖指令，使用方法：v-debounce

import '@/router/permission'  

import '@/assets/css/common.less'