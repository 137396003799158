/**
 *  @auther  yuyuanda
 *  指令文件：
 *  v-debounce：防抖指令
 */

export default (vue) => {
  /**
   * 绑定方法
   * 使用方法：v-debounce
   */
  vue.directive('debounce', { // 防抖函数指令
    inserted: function (el) {
      let timer
      el.addEventListener('click', () => {
        if (timer) {
          clearTimeout(timer)
        }
        if (!el.disabled) {
          el.disabled = true
          timer = setTimeout(() => {
            el.disabled = false
          }, 2000)
        }
      })
    }
  })

}
