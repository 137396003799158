export const WxTokenKey = "bpm_user_vant_wx_token";

export function getWxToken() {
  return localStorage.getItem(WxTokenKey);
}

export function setWxToken(token) {
  return localStorage.setItem(WxTokenKey, token);
}

export function removeWxToken() {
  return localStorage.removeItem(WxTokenKey);
}
