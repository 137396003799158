import router from ".";
import { getPageTitle } from "@/uitls";
import axios from "axios";
import { getToken, reLoad } from "./permissimport";
import { removeWxToken, WxTokenKey } from "@/uitls/wxauth";
const whiteList = [
  "previousservice",
  "addpreviousservice",
  "questionnaire",
  "QuestionnaireLogin",
  "QuestionnaireChild",
  "QuestionnaireCommiInfo",
  "QuestionnaireChildDetail",
  "singallrank",
  "singgrouprank",
  "home",
  "test",
]; // 白名单，不需要验证，直接通行！
/**
 * 目前功能很简单：修改标题
 */

router.beforeEach(async (to, from, next) => {
  if (whiteList.indexOf(to.name) >= 0) {
    document.title = getPageTitle(to.meta.title);
    if (!to.query.t) {
      next({ ...to, query: { ...to.query, t: Date.now() } });
    } else {
      next();
    }
  } else if (localStorage.getItem(WxTokenKey) == null) {
    getToken(to, next);
  } else {
    // 每次进入页面前验证token是否有效
    let locToken = localStorage.getItem(WxTokenKey);
    let res = await axios({
      method: "get",
      url: "/api/v1/token/verify-token?token=" + locToken,
    });
    if (res.data.data.isValid) {
      document.title = getPageTitle(to.meta.title);
      if (!to.query.t) {
        next({ ...to, query: { ...to.query, t: Date.now() } });
      } else {
        next();
      }
    } else {
      //无效token 直接清除，刷新界面
      removeWxToken();
      reLoad();
    }
  }
});
router.afterEach((to) => {
  to.query.t = Date.now();
});
