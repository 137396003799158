import router from "@/router";
const redirectHomeUrl = `https://open.pennonedu.com/wechat/official_account?redirect=${process.env.VUE_APP_BASE_API}`;
import axios from "axios";
import { WxTokenKey } from "@/uitls/wxauth";
import { getPageTitle } from "@/uitls";
export async function getToken(to, next) {
  let code = getQueryVariable("code");
  let state = getQueryVariable("state");
  if (code == false) {
    const redirectUrl = encodeURIComponent(window.location.href); // 当前页面链接
    const redirectTo = `https://open.pennonedu.com/wechat/official_account?redirect=${redirectUrl}`;
    window.location.href = redirectTo; // 跳转到指定链接
    // window.location.href = redirectUrl;
    return false;
  } else {
    // 回调有参数了之后再拿token
    let res = await axios({
      method: "get",
      url: `/api/v1/token/get-token?code=${code}&state=${state}`,
    });
    if (res.status == 200) {
      if (res.data.errCode == 10060) {
        window.location.href = redirectHomeUrl;
      } else {
        localStorage.setItem(WxTokenKey, res.data.data.token);
        // 知道从哪来的，登录授权后需要跳转到这个页面
        document.title = getPageTitle(to.meta.title);
        next({ ...to, replace: true });
      }
    } else {
      console.log("无法获取token");
    }
  }
}

function getQueryVariable(variable) {
  let query = window.location.hash.split("?")[1];
  let vars = (query || "").split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

export function reLoad() {
  const redirectUrl = encodeURIComponent(window.location.href); // 当前页面链接
  window.location.href = redirectUrl;
}
